<template>
  <div class="d-inline-block tooltip-wrapper">
    <fa-icon :id="shortId" :icon="['fas', icon]"></fa-icon>
    <b-tooltip :variant="variant" :placement="placement" :target="shortId" :triggers="triggers">
      {{ tooltipText }}
    </b-tooltip>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'

export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    tooltipText: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      required: false
    },
    placement: {
      type: String,
      required: false,
      default: 'top',
      validator: value => ['top', 'topleft', 'topright', 'right', 'righttop', 'rightbottom', 'bottom', 'bottomleft', 'bottomright', 'left', 'lefttop', 'leftbottom'].includes(value)
    },
    triggers: {
      type: String,
      required: false,
      default: 'hover',
      validator: value => ['click', 'hover', 'focus'].includes(value)
    }
  },
  data () {
    return {
      shortId: nanoid()
    }
  }
}
</script>

<style scoped lang="scss">
.tooltip-wrapper {
  vertical-align: middle;
  cursor: pointer;
}
</style>
