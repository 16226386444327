<template>
  <div class="card mb-4">
    <div class="card-body">
      <async-object
        should-fetch
        :fetch-method="fetchData"
      >
        <div class="form-group" v-if="layout === 'dropdown'">
          <label for="quebecTaxInput">Votre entreprise est-elle assujettie aux taxes de vente ?</label>
          <select class="custom-select" @change="formStatusChanged" v-model="form.isTaxpayer">
            <option :value="true">Oui, je dois payer la TPS/TVH et/ou la TVQ</option>
            <option :value="false">Non, je ne dois payer aucune taxe de vente</option>
          </select>
        </div>

        <div class="form-group" v-else>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="isTaxpayerCheckbox" @change="formStatusChanged" v-model="form.isTaxpayer">
            <label class="custom-control-label" for="isTaxpayerCheckbox">Mon entreprise est enregistrée aux fichiers des taxes.</label>
          </div>
        </div>

        <div class="form-group" v-if="form.isTaxpayer">
          <label for="federalTaxInput">Numéro de TPS/TVH</label>
          <input type="text" class="form-control" id="federalTaxInput" @change="formStatusChanged" v-model="form.federalTaxNumber" :class="{'is-invalid': !!errors.federalTaxNumber}" placeholder="ex.: 123123123RT0001">
          <small class="text-muted">Numéro à 15 caractères, incluant 'RT' et le numéro de dossier</small>
          <div v-if="!!errors.federalTaxNumber" class="invalid-feedback">
            {{ errors.federalTaxNumber[0] }}
          </div>
        </div>

        <div class="form-group" v-if="form.isTaxpayer">
          <label for="quebecTaxInput">Numéro de TVQ</label>
          <input type="text" class="form-control" id="quebecTaxInput" @change="formStatusChanged" v-model="form.quebecTaxNumber" :class="{'is-invalid': !!errors.quebecTaxNumber}" placeholder="ex.: 1234512345TQ0001">
          <small class="text-muted">Numéro à 16 caractères, incluant 'TQ' et le numéro de dossier</small>
          <div v-if="!!errors.quebecTaxNumber" class="invalid-feedback">
            {{ errors.quebecTaxNumber[0] }}
          </div>
        </div>

        <button-loader :disabled="isProcessing || !canSubmit" :loading="isProcessing" @click="submit">
          Enregistrer
        </button-loader>
      </async-object>
    </div>
  </div>
</template>

<script>
import { pick } from 'lodash-es'
import http from '@/utils/http'
import ButtonLoader from '@/components/common/ButtonLoader'
import userData from '@/mixins/user-data'
import AsyncObject from '@/components/common/AsyncObject'
import useValidator from '@/mixins/useValidator'
import formError from '@/mixins/form-error'

export default {
  mixins: [userData, useValidator, formError],
  components: {
    AsyncObject,
    ButtonLoader
  },
  props: {
    // If layout is set as 'dropdown', display a select input instead of a checkbox
    layout: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      form: {
        isTaxpayer: true,
        federalTaxNumber: '',
        quebecTaxNumber: ''
      },
      originalValues: {},
      isProcessing: false,
      isReady: false
    }
  },
  computed: {
    canSubmit () {
      return this.form.isTaxpayer === false || (this.form.federalTaxNumber && this.form.quebecTaxNumber)
    },
    validationRules () {
      return {
        federalTaxNumber: 'federal-tax-number',
        quebecTaxNumber: 'quebec-tax-number'
      }
    }
  },
  methods: {
    async fetchData () {
      const { data } = await http.get(`/v1/stores/${this.currentStoreId}/settings`)

      this.form = pick({
        ...data,
        isTaxpayer: Boolean((Number(data.isTaxpayer)))
      }, ['isTaxpayer', 'federalTaxNumber', 'quebecTaxNumber'])

      if (!data || data.isTaxpayer === undefined) {
        this.form.isTaxpayer = ''
      }

      this.originalValues = { ...this.form }

      this.isReady = true
    },
    formStatusChanged () {
      this.$emit('formStatusChanged', true)
    },
    async submit () {
      this.isProcessing = true

      try {
        const payload = this.form.isTaxpayer ? { ...this.form, isTaxpayer: true } : {
          isTaxpayer: false,
          federalTaxNumber: '',
          quebecTaxNumber: ''
        }

        if (payload.isTaxpayer) {
          const isValidated = this.validate(payload, this.validationRules)

          if (!isValidated) {
            this.isProcessing = false
            this.toastFormError(false)
            return
          }
        }

        this.errors = {}

        await http.put(`/v1/stores/${this.currentStoreId}/settings`, payload)

        // Seed default taxes if taxPayer status has changed
        if (payload.isTaxpayer && !this.originalValues.isTaxpayer) {
          try {
            await http.post(`/v1/stores/${this.currentStoreId}/tax-rates/seed`)
          } catch {
            // Ignore error if taxes have already been seeded
          }
        }

        this.$emit('formStatusChanged', false)
        this.$emit('saved')

        this.$toasted.success('Paramètres enregistrés avec succès.')
        this.isProcessing = false
      } catch (e) {
        console.error('Error', e)
        this.isProcessing = false
        this.$toasted.error("Une erreur s'est produite. Veuillez réessayer.")
      }
    }
  }
}
</script>
