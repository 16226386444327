<template>
  <app-layout>
    <div class="px-4 p-sm-5 container">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Taxes</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <tax-registration-form @formStatusChanged="formStatusChanged" @saved="refreshRates"></tax-registration-form>
        </div>
      </div>

      <div class="my-4" v-if="taxRates && taxRates.length">
        <h2 class="mb-3">Taux applicables</h2>

        <div class="row">
          <div class="col-12">
            <tax-rate-table @formStatusChanged="formStatusChanged" ></tax-rate-table>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import TaxRegistrationForm from '@/components/settings/TaxRegistrationForm'
import TaxRateTable from '@/components/settings/TaxRateTable'
import userData from '@/mixins/user-data'
import promptUnsaved from '@/mixins/promptUnsaved'

export default {
  mixins: [userData, promptUnsaved],
  components: {
    TaxRegistrationForm,
    TaxRateTable
  },
  computed: {
    taxRates () {
      return this.$store.state.taxRates.collection
    }
  },
  methods: {
    async refreshRates () {
      await this.$store.dispatch('taxRates/fetchOrRefresh', this.currentStoreId)
    }
  }
}
</script>
