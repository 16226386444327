import config from '@/config'
import { File as StorageFile } from '@/store/types/files'

/**
 * Force saving a file to local disk of user by manually creating a link and downloading it
 * @param blob represents the data coming from the server as a Blob object
 * @param type represents the type of the file to be downloaded
 * @param path represents the full path where it should be added including the file name ex: 'file.png' or './path/to/file.png'
 */
export const initiateFileDownload = (blob: Blob, type: string, path: string): void => {
  const url = window.URL.createObjectURL(new Blob([blob], { type }))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', path)
  document.body.appendChild(link)
  link.click()
}

/**
 * Returns the public url for S3 Storage file
 * @param file
 */
export const getStorageFileSource = (file: StorageFile) => {
  return `${config.storage.cdnUrl}/${file.path}`
}
