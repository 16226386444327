export const getProvinceName = (code: string): string | null => {
  const provincesByCode: Record<string, string> = {
    AB: 'Alberta',
    BC: 'Colombie-Britannique',
    PE: 'Île-du-Prince-Édouard',
    MB: 'Manitoba',
    NB: 'Nouveau-Brunswick',
    NS: 'Nouvelle-Écosse',
    NU: 'Nunavut',
    ON: 'Ontario',
    QC: 'Québec',
    SK: 'Saskatchewan',
    NL: 'Terre-Neuve-et-Labrador',
    YT: 'Yukon',
    NT: 'Territoires du Nord-Ouest'
  }

  return provincesByCode[code] || null
}
