<template>
  <app-layout>
    <div class="px-4 p-sm-5 container">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Frais de port</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <shipping-settings-form></shipping-settings-form>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import ShippingSettingsForm from '@/components/settings/ShippingSettingsForm'
import userData from '@/mixins/user-data'

export default {
  mixins: [userData],
  components: {
    ShippingSettingsForm
  }
}
</script>
