<template>
  <select class="custom-select" :class="{'border-danger': hasError}" :value="value" @input="handleChange">
    <option v-for="option in options" :key="option.value" :value="option.value">
      {{ option.label }}
    </option>
  </select>
</template>

<script>
import { orderBy } from 'lodash-es'
import inputMixin from '@/modules/forms/components/inputs/inputMixin'

export default {
  mixins: [inputMixin],
  computed: {
    options () {
      const provinces = [
        {
          value: 'AB',
          label: 'Alberta'
        },
        {
          value: 'BC',
          label: 'Colombie-Britannique'
        },
        {
          value: 'MB',
          label: 'Manitoba'
        },
        {
          value: 'NB',
          label: 'Nouveau-Brunswick'
        },
        {
          value: 'NL',
          label: 'Terre-Neuve'
        },
        {
          value: 'NT',
          label: 'Territoires du Nord-Ouest'
        },
        {
          value: 'NS',
          label: 'Nouvelle-Écosse'
        },
        {
          value: 'NU',
          label: 'Nunavut'
        },
        {
          value: 'ON',
          label: 'Ontario'
        },
        {
          value: 'PE',
          label: 'Île-du-Prince-Édouard'
        },
        {
          value: 'QC',
          label: 'Québec'
        },
        {
          value: 'SK',
          label: 'Saskatchewan'
        },
        {
          value: 'YT',
          label: 'Yukon'
        }
      ]

      return orderBy(provinces, ['label'], ['asc'])
    }
  }
}
</script>
