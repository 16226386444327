<template>
  <div>
    <div
      v-if="variant === 'block'"
      class="upload-container block"
      :class="[`block-${size}`, { 'upload-container-disable': disabled }]"
      @click.self="handleSubmission"
      @drop.prevent="handleDnD"
      @dragover.prevent
    >
      <b-spinner v-if="loading" type="grow" small></b-spinner>
      <div v-else-if="!hideText" @click="handleSubmission">{{ text }} ou <span role="button"><u>Parcourir</u></span></div>
    </div>
    <div
      v-else-if="variant === 'square'"
      class="upload-container square"
      :class="[`square-${size}`, { 'upload-container-disable': disabled }]"
      @click.self="handleSubmission"
      @drop.prevent="handleDnD"
      @dragover.prevent
    >
      <b-spinner v-if="loading" type="grow" small></b-spinner>
      <div v-else @click="handleSubmission">
        <fa-icon size="lg" :icon="['fas', icon]"></fa-icon>
        <span v-if="!hideText" class="d-block small mt-2"> {{ text }} </span>
      </div>
    </div>
    <button-loader
      v-else
      class="text-primary shadow-none"
      :class="{'btn-sm': size === 'sm'}"
      variant="default"
      :disabled="disabled"
      :loading="loading"
      @click.self="handleSubmission"
    >
      <slot></slot>
    </button-loader>
    <input ref="uploadButton" class="d-none" type="file" name="file" :accept="accept" @change="handleChange" :multiple="multiple" />
  </div>
</template>

<script>

import ButtonLoader from '@/components/common/ButtonLoader'

export default {
  components: {
    ButtonLoader
  },
  props: {
    maxFileSize: {
      type: Number,
      required: false,
      default: 2 // in mb
    },
    accept: {
      type: String,
      required: false,
      default: '.png,.jpg,.jpeg'
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true
    },
    variant: {
      type: String,
      required: false,
      default: 'block',
      validator: value => ['block', 'square', 'btn'].includes(value)
    },
    size: {
      type: String,
      required: false,
      default: 'md',
      validator: value => ['sm', 'md'].includes(value)
    },
    icon: {
      type: String,
      required: false,
      default: 'upload'
    },
    text: {
      type: String,
      required: false,
      default: 'Déposez une image'
    },
    hideText: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    handleDnD (event) {
      if (!event.dataTransfer.files) {
        return
      }

      const files = event.dataTransfer.files

      const isValid = this.validate(files)

      if (!isValid) {
        return
      }

      this.$emit('click', files)
    },
    handleChange (event) {
      if (!event.target.files) {
        return
      }

      const files = event.target.files

      const isValid = this.validate(files)

      if (!isValid) {
        return
      }

      this.$emit('click', files)
      event.target.value = ''
    },
    handleSubmission () {
      if (!this.$refs.uploadButton) {
        return
      }

      this.$refs.uploadButton.click()
    },
    validate (files) {
      if (!files || !files.length) {
        this.$toasted.error("Aucun fichier n'a été sélectionné. Veuillez réessayer.")
        this.$emit('error')
        return false
      }

      for (const file of files) {
        if (this.maxFileSize) {
          const fileSize = file.size / 1024 / 1024 // in mb

          if (fileSize > this.maxFileSize) {
            this.$toasted.error(`La taille du fichier dépasse la limite de ${this.maxFileSize}Mo. Veuillez réessayer.`)
            this.$emit('error', 'size')
            return false
          }
        }
      }

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
  .upload-container {
    border: 1px dashed lightgray;
    border-radius: 6px;
    background: #fafafa;
    cursor: default;

    &.block {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.block-sm {
      height: 35px;
    }

    &.block-md {
      height: 70px;
    }

    &.square {
      text-align: center;
      color: #a6a6a6;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &.square-sm {
      min-width: 90px;
      max-width: 90px;
      min-height: 90px;
      max-height: 90px;
    }

    &.square-md {
      min-width: 180px;
      max-width: 180px;
      min-height: 180px;
      max-height: 180px;
    }

    &.upload-container-disable {
      pointer-events: none;
    }
  }
</style>
