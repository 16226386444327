import Vue from 'vue'

export default Vue.extend({
  data () {
    return {
      intervalError: null
    }
  },
  methods: {
    scrollTop () {
      this.intervalError = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalError as any)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 20) as any
    },
    toastFormError (scroll = true, errorMessage = 'Vous avez une ou plusieurs erreur(s) dans votre formulaire'): void {
      this.$toasted.error(errorMessage)

      if (scroll) {
        this.scrollTop()
      }
    }
  }
})
