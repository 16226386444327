<template>
  <app-layout>
    <div class="px-4 p-sm-5 container">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Profil entreprise</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <profile-form @formStatusChanged="formStatusChanged" />
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import ProfileForm from '@/components/settings/ProfileForm'
import userData from '@/mixins/user-data'
import promptUnsaved from '@/mixins/promptUnsaved'

export default {
  mixins: [userData, promptUnsaved],
  components: {
    ProfileForm
  }
}
</script>
