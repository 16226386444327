<template>
  <select class="custom-select" :class="{'border-danger': hasError}" :value="value" @input="handleChange">
    <option v-for="option in options" :key="option.value" :value="option.value" :selected="option.selected">
      {{ option.label }}
    </option>
  </select>
</template>

<script>
import { orderBy } from 'lodash-es'
import inputMixin from '@/modules/forms/components/inputs/inputMixin'

export default {
  mixins: [inputMixin],
  computed: {
    options () {
      const country = [
        {
          value: 'CA',
          label: 'Canada',
          selected: true
        }
      ]

      return orderBy(country, ['label'], ['asc'])
    }
  }
}
</script>
