<template>
  <tr>
    <td>{{ taxRate.provinceName }}</td>
    <td>{{ taxRate.tax ? taxRate.tax.label : '' }}</td>
    <template v-if="!isEditing">
      <td>{{ formatTaxRate(taxRate.rate) }} %</td>
      <td class="text-right action-col">
        <a href="" class="mr-3" @click.prevent="openEditView">
          <fa-icon :icon="['fas', 'pen']"></fa-icon>
        </a>
        <a href="" @click.prevent="$emit('delete')">
          <fa-icon :icon="['fas', 'times']"></fa-icon>
        </a>
      </td>
    </template>
    <template v-else>
      <td>
        <div class="input-group">
          <input type="text" class="form-control" @keypress="formStatusChanged(true)" v-model="form.rate">
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </td>
      <td class="text-right">
        <button class="btn btn-sm btn-primary mr-2" :disabled="isProcessing" @click="handleSubmit">Enregistrer</button>
        <button class="btn btn-link" @click="closeEditView">Annuler</button>
      </td>
    </template>
  </tr>
</template>

<script>
export default {
  props: {
    taxRate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isEditing: false,
      isProcessing: false,
      form: {
        rate: ''
      }
    }
  },
  methods: {
    formStatusChanged (state) {
      this.$emit('formStatusChanged', state)
    },
    formatTaxRate (rate) {
      return Number(rate)
    },
    openEditView () {
      this.form.rate = this.taxRate.rate
      this.isEditing = true
    },
    closeEditView () {
      this.formStatusChanged(false)
      this.isEditing = false
      this.isProcessing = false
    },
    handleSubmit () {
      this.formStatusChanged(false)
      this.isProcessing = true
      this.$emit('edit', this.taxRate.id, this.form.rate, this.closeEditView)
    }
  }
}
</script>

<style lang="scss" scoped>
.action-col a {
  color: rgba(0, 0, 0, 0.5);
}

.input-group {
  width: 160px;
}
</style>
