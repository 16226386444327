<template>
  <div class="card mb-4">
    <div class="card-body" :class="{ 'p-0': fetched }">
      <async-object
        should-fetch
        :fetch-method="fetchData"
        :on-fetched="setFetched"
      >
        <div class="table-responsive">
          <table class="table border-top-none table-hover nowrap-table">
            <thead>
            <tr>
              <th>Province</th>
              <th>Taxe</th>
              <th>Taux</th>
              <td class="text-right">
                <a href="" @click.prevent="showCreateForm" v-if="!isCreateFormVisible">
                  <fa-icon :icon="['fas', 'plus']" class="mr-1"></fa-icon>
                  Ajouter
                </a>
              </td>
            </tr>
            </thead>
            <tax-rate-table-form-row
              v-if="isCreateFormVisible"
              :processing="isCreating"
              @cancel="hideCreateForm"
              @submit="createTaxRate"
              @formStatusChanged="formStatusChanged"
            ></tax-rate-table-form-row>
            <tbody>
            <tax-rate-table-row
              v-for="taxRate in sortedTaxRates"
              :key="taxRate.id"
              :tax-rate="taxRate"
              @delete="handleDelete(taxRate.id)"
              @edit="handleEdit"
              @formStatusChanged="formStatusChanged"
            ></tax-rate-table-row>
            </tbody>
          </table>
        </div>
      </async-object>
    </div>
  </div>
</template>

<script>
import { map, orderBy } from 'lodash-es'
import AsyncObject from '@/components/common/AsyncObject'
import userData from '@/mixins/user-data'
import { getProvinceName } from '@/utils/provinces'
import TaxRateTableFormRow from './TaxRateTableFormRow'
import TaxRateTableRow from './TaxRateTableRow'

export default {
  mixins: [userData],
  components: {
    AsyncObject,
    TaxRateTableFormRow,
    TaxRateTableRow
  },
  data () {
    return {
      fetched: false,
      isCreateFormVisible: false,
      isCreating: false
    }
  },
  computed: {
    sortedTaxRates () {
      const rates = map(this.$store.state.taxRates.collection, (item) => ({
        ...item,
        provinceName: getProvinceName(item.stateCode)
      }))

      return orderBy(rates, ['provinceName', 'taxRate.label'], ['asc', 'asc'])
    }
  },
  methods: {
    formStatusChanged (state) {
      this.$emit('formStatusChanged', state)
    },
    async fetchData () {
      await this.$store.dispatch('taxRates/fetchOrRefresh', this.currentStoreId)
    },
    async createTaxRate (values) {
      this.isCreating = true

      await this.$store.dispatch('taxRates/create', {
        ...values,
        storeId: this.currentStoreId
      })

      this.isCreating = false
      this.hideCreateForm()
    },
    async handleDelete (taxRateId) {
      await this.$store.dispatch('taxRates/delete', {
        id: taxRateId,
        storeId: this.currentStoreId
      })
    },
    async handleEdit (taxRateId, rate, doneCallback) {
      await this.$store.dispatch('taxRates/update', {
        id: taxRateId,
        storeId: this.currentStoreId,
        rate
      })

      await this.fetchData()

      doneCallback()
    },
    setFetched () {
      this.fetched = true
    },
    showCreateForm () {
      this.isCreateFormVisible = true
    },
    hideCreateForm () {
      this.isCreateFormVisible = false
    }
  }
}
</script>
