<template>
  <tbody>
  <tr>
    <td>
      <province-selector input-id="province-selector" :value="form.stateCode" @value-change="handleStateCode"></province-selector>
    </td>
    <td>
      <select class="custom-select" @keypress="formStatusChanged(true)" v-model="form.taxId">
        <option v-for="tax in taxes" :key="tax.id" :value="tax.id">{{ tax.label }}</option>
      </select>
    </td>
    <td>
      <div class="input-group">
        <input type="text" class="form-control" @keypress="formStatusChanged(true)" v-model="form.rate">
        <div class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
      </div>
    </td>
    <td class="text-right">
      <button class="btn btn-sm btn-primary mr-2" :disabled="processing" @click="handleSubmit">Enregistrer</button>
      <button class="btn btn-link" @click="cancel">Annuler</button>
    </td>
  </tr>
  </tbody>
</template>

<script>
import ProvinceSelector from '@/components/forms/inputs/ProvinceSelector'

export default {
  props: {
    processing: {
      type: Boolean
    }
  },
  data () {
    return {
      form: {
        stateCode: '',
        taxId: '',
        rate: ''
      }
    }
  },
  components: {
    ProvinceSelector
  },
  computed: {
    taxes () {
      return this.$store.getters['taxRates/getAvailableTaxes']
    }
  },
  methods: {
    formStatusChanged (state) {
      this.$emit('formStatusChanged', state)
    },
    cancel () {
      this.formStatusChanged(false)
      this.$emit('cancel')
    },
    handleStateCode (code) {
      this.form.stateCode = code
      this.formStatusChanged(true)
    },
    handleSubmit () {
      this.formStatusChanged(false)
      this.$emit('submit', this.form)
    }
  }
}
</script>

<style lang="scss" scoped>
td {
  vertical-align: middle;
}

.input-group {
  width: 160px;
}
</style>
