<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-9 col-xl-9">
        <async-object
          should-fetch
          :fetch-method="fetchData"
        >
          <transition name="fade">
            <div class="card mb-4">
              <div class="card-header">
                Informations générales
                <span class="text-danger">*</span>
              </div>
              <div class="card-body">
                <div class="form-group w-100 pr-2">
                  <label for="store-name">Nom de la boutique</label>
                  <input
                    class="form-control w-100"
                    id="store-name"
                    :value="profile.storeName"
                    @input="(event) => handleForm('storeName', event.target.value)"
                  >
                </div>

                <div class="form-group">
                  <label for="profile-category">Principale catégorie</label>
                  <store-activity-selector
                    class="form-control w-100"
                    input-id="profile-category"
                    id="profile-category"
                    :value="profile.category"
                    @value-change="(value) => handleForm('category', value)"
                    :has-error="showErrors && !!errors.category"
                  />
                  <div v-if="showErrors && !!errors.category" class="invalid-feedback">
                    {{ errors.category[0] }}
                  </div>
                </div>

                <div class="form-group" v-if="profile && profile.category === 'other'">
                  <label for="profile-other-category">Précisez</label>
                  <input
                    type="text"
                    class="form-control w-100"
                    :class="{ 'is-invalid': showErrors && !!errors.otherCategory }"
                    id="profile-other-category"
                    :value="profile.otherCategory"
                    @input="(event) => handleForm('otherCategory', event.target.value)"
                  >
                  <div v-if="showErrors && !!errors.otherCategory" class="invalid-feedback">
                    {{ errors.otherCategory[0] }}
                  </div>
                </div>

                <div class="form-group w-100 pr-2">
                  <label for="profile-description">Description de la boutique</label>
                  <textarea
                    type="text"
                    class="form-control textarea-description w-100"
                    :class="{ 'is-invalid': showErrors && !!errors.description }"
                    id="profile-description"
                    :value="profile.description"
                    @input="(event) => handleForm('description', event.target.value)"
                    placeholder="Expliquez brièvement à vos clients ce que vous vendez, l'historique de la boutique, etc."
                  ></textarea>
                  <div v-if="showErrors && !!errors.description" class="invalid-feedback">
                    {{ errors.description[0] }}
                  </div>
                </div>
                <div class="form-group w-100 pr-2">
                  <label for="profile-tagline">Slogan de la boutique</label>
                  <input
                    class="form-control w-100"
                    id="profile-tagline"
                    :value="profile.tagline"
                    @input="(event) => handleForm('tagline', event.target.value)"
                    placeholder="Ajoutez une courte phrase accrocheuse qui résume la valeur de votre boutique."
                  >
                </div>
              </div>
            </div>
          </transition>

          <transition name="fade">
            <div class="card mb-4">
              <div class="card-header">
                Adresse de la boutique
                <span class="text-danger">*</span>
              </div>
              <div class="card-body">
                <p class="mb-4">Si l'adresse de votre boutique est différente de votre adresse légale, vous pouvez la modifier ici.</p>

                <div class="d-flex flex-column flex-sm-row">
                  <div class="form-group w-100 pr-sm-2">
                    <label for="profile-address">Adresse</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.address }"
                      id="profile-address"
                      :value="profile.address"
                      @input="(event) => handleForm('address', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.address" class="invalid-feedback">
                      {{ errors.address[0] }}
                    </div>
                  </div>

                  <div class="form-group w-100 pl-sm-2">
                    <label for="profile-address2">Adresse 2</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      id="profile-address2"
                      :value="profile.address2"
                      @input="(event) => handleForm('address2', event.target.value)"
                    >
                  </div>
                </div>

                <div class="d-flex flex-column flex-sm-row">
                  <div class="form-group mobile-wid-100 w-75 pr-sm-2">
                    <label for="profile-city">Ville</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.city }"
                      id="profile-city"
                      :value="profile.city"
                      @input="(event) => handleForm('city', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.city" class="invalid-feedback">
                      {{ errors.city[0] }}
                    </div>
                  </div>

                  <div class="form-group mobile-wid-100 w-25 pl-sm-2">
                    <label for="profile-postalCode">Code postal</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.postalCode }"
                      id="profile-postalCode"
                      :value="profile.postalCode"
                      @input="(event) => handleForm('postalCode', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.postalCode" class="invalid-feedback">
                      {{ errors.postalCode[0] }}
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-column flex-sm-row">
                  <div class="form-group w-100 pr-sm-2">
                    <label for="profile-province">Province</label>
                    <province-selector
                      class="form-control w-100"
                      input-id="profile-province"
                      id="profile-province"
                      :value="profile.province"
                      @value-change="(value) => handleForm('province', value)"
                      :has-error="showErrors && !!errors.province"
                    />
                    <div v-if="showErrors && !!errors.province" class="invalid-feedback">
                      {{ errors.province[0] }}
                    </div>
                  </div>
                  <div class="form-group w-100 pl-sm-2">
                    <label for="profile-country">Pays</label>
                    <country-selector
                      class="form-control w-100"
                      input-id="profile-province"
                      id="profile-country"
                      :value="profile.country"
                      @value-change="(value) => handleForm('country', value)"
                      :has-error="showErrors && !!errors.country"
                    />
                    <div v-if="showErrors && !!errors.country" class="invalid-feedback">
                      {{ errors.country[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="card mb-4">
              <div class="card-header">
                Logo de votre boutique

                <icon-tooltip icon="info-circle" tooltipText="Dimension recommandée 180 x 180px" placement="right" />
              </div>
              <div class="card-body">
                <template v-if="getPhotoUrlByKey('profile')">
                  <file-image class="mb-2" :src="getPhotoUrlByKey('profile')" />
                  <div class="d-flex justify-content-between">
                    <file-upload-button variant="btn" size="sm" class="px-0" @click="(files) => handleUpload(files, 'profile')">
                      Remplacer
                    </file-upload-button>
                    <button class="btn btn-link text-danger btn-sm px-0" @click="() => handleDelete('profile')">Enlever</button>
                  </div>
                </template>
                <file-upload-button v-else
                                    class="d-flex justify-content-center"
                                    variant="square"
                                    text="Déposez votre logo"
                                    :multiple="false"
                                    @click="(files) => handleUpload(files, 'profile')" />
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="card mb-4">
              <div class="card-header">
                Bannière de votre boutique

                <icon-tooltip icon="info-circle" tooltipText="Dimension recommandée 1920 x 300px" placement="right" />
              </div>
              <div class="card-body">
                <template v-if="getPhotoUrlByKey('banner')">
                  <div class="header-banner mb-2" :style="{ backgroundImage: `url('${getPhotoUrlByKey('banner')}')` }"/>
                  <div class="d-flex justify-content-between">
                    <file-upload-button variant="btn" size="sm" class="px-0" @click="(files) => handleUpload(files, 'banner')">
                      Remplacer
                    </file-upload-button>
                    <button class="btn btn-link text-danger btn-sm px-0" @click="() => handleDelete('banner')">Enlever</button>
                  </div>
                </template>
                <file-upload-button v-else
                                    class="d-flex justify-content-center"
                                    text="Déposez votre bannière"
                                    :multiple="false"
                                    @click="(files) => handleUpload(files, 'banner')" />
              </div>
            </div>
          </transition>

          <transition name="fade">
            <div class="my-4">
              <button-loader :disabled="isProcessing" :loading="isProcessing" @click="handleSave">
                Enregistrer
              </button-loader>
            </div>
          </transition>
        </async-object>
      </div>
    </div>
  </div>
</template>

<script>
import { get, pick, isEmpty, omit } from 'lodash-es'
import userData from '@/mixins/user-data'
import useValidator from '@/mixins/useValidator'
import AsyncObject from '@/components/common/AsyncObject'
import CountrySelector from '@/components/forms/inputs/CountrySelector'
import ProvinceSelector from '@/components/forms/inputs/ProvinceSelector'
import StoreActivitySelector from '@/components/forms/inputs/StoreActivitySelector'
import ButtonLoader from '@/components/common/ButtonLoader'
import FileImage from '@/components/common/FileImage'
import FileUploadButton from '@/components/common/FileUploadButton'
import { getStorageFileSource } from '@/utils/files'
import http from '@/utils/http'
import formError from '@/mixins/form-error'
import IconTooltip from '@/components/common/IconTooltip'

const FILE_TYPE = 'storeImage'

export default {
  mixins: [userData, useValidator, formError],
  components: {
    IconTooltip,
    FileImage,
    FileUploadButton,
    AsyncObject,
    CountrySelector,
    ProvinceSelector,
    ButtonLoader,
    StoreActivitySelector
  },
  data () {
    return {
      isProcessing: false,
      showErrors: false,
      profile: {},
      photos: {
        profile: {
          static: null,
          saved: null
        },
        banner: {
          static: null,
          saved: null
        }
      }
    }
  },
  computed: {
    validationRules () {
      return {
        storeName: 'required',
        address: 'required|min:4|max:50',
        city: 'required|min:2',
        postalCode: 'required|ca-postalcode',
        province: 'required|ca-provincialcode',
        country: 'required|ca-countrycode',
        description: 'required',
        category: 'required',
        otherCategory: 'required_if:category,other'
      }
    }
  },
  methods: {
    getPhotoUrlByKey (key) {
      if (this.photos[key].saved) {
        return getStorageFileSource(this.photos[key].saved)
      }

      return this.photos[key].static ? URL.createObjectURL(this.photos[key].static) : null
    },
    initForm () {
      let defaultAddress = {}

      const legalProfile = this.$store.state.profile.data
      const publicProfile = this.$store.state.publicProfile.data || {}

      if (!(publicProfile && publicProfile.address && publicProfile.postalCode)) {
        defaultAddress = pick(legalProfile, ['address', 'address2', 'city', 'postalCode', 'province', 'country'])
      }

      this.profile = {
        ...defaultAddress,
        ...omit(publicProfile, ['store', 'profilePhoto', 'bannerPhoto']),
        storeName: get(legalProfile, 'store.name')
      }

      this.photos = {
        profile: {
          saved: publicProfile.profilePhoto
        },
        banner: {
          saved: publicProfile.bannerPhoto
        }
      }

      this.validate(this.profile, this.validationRules)
    },
    handleUpload (files, key) {
      const [file] = files

      if (!file) {
        return
      }

      this.photos = {
        ...this.photos,
        [key]: {
          saved: null,
          static: file
        }
      }
    },
    handleDelete (key) {
      this.photos = {
        ...this.photos,
        [key]: {
          saved: null,
          static: null
        }
      }
    },
    handleForm (key, value) {
      if (Object.entries(this.profile).length > 0) {
        this.$emit('formStatusChanged', true)
      }
      this.profile = {
        ...this.profile,
        [key]: value
      }
      this.validate(this.profile, this.validationRules)
    },
    async fetchData () {
      try {
        this.isProcessing = true

        await this.$store.dispatch('profile/fetch', this.currentStoreId)
        await this.$store.dispatch('publicProfile/fetch', this.currentStoreId)
      } catch (error) {
        if (error.response.status === 404) {
          // user needs to create profile
          return
        }
        this.serverError = error
      } finally {
        this.initForm()
        this.isProcessing = false
      }
    },
    async handleSave () {
      if (!isEmpty(this.errors)) {
        this.showErrors = true
        this.toastFormError()
        return
      }

      try {
        this.showErrors = false
        this.isProcessing = true
        const profilePhotoId = await this.uploadFileImage(this.photos.profile.static) || this.photos.profile.saved
        const bannerPhotoId = await this.uploadFileImage(this.photos.banner.static) || this.photos.banner.saved

        await this.$store.dispatch('publicProfile/update', {
          storeId: this.currentStoreId,
          payload: {
            ...this.profile,
            profilePhotoId,
            bannerPhotoId,
            otherCategory: this.profile && this.profile.category === 'other' ? this.profile.otherCategory : null
          }
        })

        this.$emit('formStatusChanged', false)
        this.$emit('saved')

        this.$toasted.success('Profil enregistré avec succès.')
      } catch (error) {
        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      } finally {
        this.isProcessing = false
      }
    },
    async uploadFileImage (staticFile) {
      if (!staticFile) {
        return null
      }

      const formData = new FormData()
      formData.append('file', staticFile)
      formData.append('type', FILE_TYPE)

      const { data: file } = await http.post(`/v1/stores/${this.currentStoreId}/file`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )

      return file.id
    }
  }
}
</script>

<style lang="scss" scoped>
.invalid-feedback {
  display: block;
  font-size: 13px;
}

.textarea-description {
  height: 150px;
}

.header-banner {
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
}
</style>
