<template>
  <div :class="`position-relative d-flex justify-content-${positionClassName}`" @click="$emit('click')">
    <div class="file-loader" v-if="loading">
      <b-spinner type="grow"></b-spinner>
    </div>
    <img class="file-image shadow-sm border position-relative" :class="`file-${this.size}`" :src="src" :alt="alt" />
  </div>
</template>

<script>
export default {
  computed: {
    positionClassName () {
      if (this.position === 'center') {
        return this.position
      }

      return `flex-${this.position}`
    }
  },
  props: {
    src: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'md',
      validator: value => ['sm', 'md'].includes(value)
    },
    alt: {
      type: String,
      required: false,
      default: ''
    },
    loading: {
      type: Boolean,
      required: false
    },
    position: {
      type: String,
      required: false,
      default: 'center',
      validator: value => ['start', 'end', 'center'].includes(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.file-sm{
  min-width: 90px;
  max-width: 90px;
  min-height: 90px;
  max-height: 90px;
}

.file-md {
  min-width: 180px;
  max-width: 180px;
  min-height: 180px;
  max-height: 180px;
}

.file-image {
  background: #ffffff;
  object-fit: contain;
  object-position: center;
}

.file-loader {
  z-index: 1;
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  opacity: 0.5;
}
</style>
